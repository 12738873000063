exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-auxo-digital-tsx": () => import("./../../../src/pages/about-auxo-digital.tsx" /* webpackChunkName: "component---src-pages-about-auxo-digital-tsx" */),
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin.tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-ai-for-execs-tsx": () => import("./../../../src/pages/ai-for-execs.tsx" /* webpackChunkName: "component---src-pages-ai-for-execs-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-fetch-data-tsx": () => import("./../../../src/pages/fetch-data.tsx" /* webpackChunkName: "component---src-pages-fetch-data-tsx" */),
  "component---src-pages-iamanauxomember-tsx": () => import("./../../../src/pages/iamanauxomember.tsx" /* webpackChunkName: "component---src-pages-iamanauxomember-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-spin-wheel-tsx": () => import("./../../../src/pages/spin-wheel.tsx" /* webpackChunkName: "component---src-pages-spin-wheel-tsx" */),
  "component---src-pages-test-tsx": () => import("./../../../src/pages/test.tsx" /* webpackChunkName: "component---src-pages-test-tsx" */),
  "component---src-pages-thank-you-ai-leader-tsx": () => import("./../../../src/pages/thank-you-ai-leader.tsx" /* webpackChunkName: "component---src-pages-thank-you-ai-leader-tsx" */),
  "component---src-pages-thank-you-pdf-sent-tsx": () => import("./../../../src/pages/thank-you-pdf-sent.tsx" /* webpackChunkName: "component---src-pages-thank-you-pdf-sent-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-pages-treasure-hunt-terms-tsx": () => import("./../../../src/pages/treasureHunt-terms.tsx" /* webpackChunkName: "component---src-pages-treasure-hunt-terms-tsx" */),
  "component---src-pages-treasure-hunt-tsx": () => import("./../../../src/pages/treasure-hunt.tsx" /* webpackChunkName: "component---src-pages-treasure-hunt-tsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-whatsapp-terms-tsx": () => import("./../../../src/pages/whatsapp-terms.tsx" /* webpackChunkName: "component---src-pages-whatsapp-terms-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

